import React from 'react';
import { Link } from 'gatsby';
import LargeDownloadButton from 'common/src/components/LargeDownloadButton';
import SimplePageSection from '../../containers/SaasModern/SimplePage';
import PricingSection from '../../containers/SaasModern/Pricing';

const PageContent = () => {
  const content = (
    <div>
      <h2>
        <span style={{ fontSize: 14 }}>March 9, 2019</span>
        <br />
        Announcing PrintableCal 2019
      </h2>
      <p>
        We're very excited to announce that PrintableCal 2019 is now available!
        We've been working hard on this update for over 2 years. It's a massive
        improvement over the previous version, packed with new templates and
        features, a new user interface, a PowerPoint add-in, better performance,
        and much more. See the{' '}
        <Link to="/Whats-New-Version-2019">release notes</Link> for more
        details. Also, please be aware the latest version of PrintableCal is no
        longer compatible with Windows XP.
      </p>
      <LargeDownloadButton />
      <h2>Some history</h2>
      <p>
        PrintableCal v1.0 was originally released back in 2013. Since then,
        there have been 47 minor releases, containing hundreds of incremental
        updates and improvements - demonstrating our continuing commitment to
        customer satisfaction and our goal to make PrintableCal the best
        solution for generating printable calendars. All those updates were free
        for anyone that purchased version 1.x.
      </p>
      <h2>A sustainable licensing model</h2>
      <p>
        PrintableCal 2019 is our single biggest update yet, but we're not going
        to stop there. We plan to continue to improve PrintableCal. To ensure
        PrintableCal's continued viability, we're switching to a
        subscription-based licensing model. Rather than paying up-front for a
        license and only receiving updates and support until the next major
        release, the new model provides a low-cost monthly option and a
        discounted yearly option. Updates and support will be free for as long
        as your subscription is active, and you can easily switch between plans
        as your needs change. A free Lite version will also continue to be
        offered. This new licensing model will help us continue to improve and
        support PrintableCal.
      </p>
      <h2>Customers of the previous version receive a 50% discount</h2>
      <p>
        To help with the transition, if you purchased version 1.x you can
        receive a 50% discount for the first year when upgrading to the latest version of PrintableCal. To request the discount, please{' '}
        <Link to="/#contact_us_section">contact us</Link>. Include the name or
        email address used when your version 1.x license was purchased. The 50%
        discount will be available for any of the license types (Standard,
        Premium, or Ultimate), regardless of which you purchased previously.
        Thank you for your business and for sticking with us through the years.
        We're excited to see where the future of PrintableCal takes both us and
        you!
      </p>
      <h2>A comparison of the license types</h2>
      <p>
        Different license types continue to be provided depending on your needs.
         See below for a feature comparison and pricing.{' '}
        <strong>
          These prices are before the 50% discount for previous customers.
        </strong>{' '}
        See above for instructions on how to claim your discount.
      </p>
      <PricingSection showTitle={false} />
    </div>
  );

  return (
    <SimplePageSection
      title="Upgrade From PrintableCal 1.x to PrintableCal 2020"
      description="If you previously purchased a PrintableCal 1.x license, you can get a discount when you upgrade to PrintableCal 2020. There have been many improvements since version 1.x."
      keywords="PrintableCal, upgrade, update, PrintableCal 2020"
      content={content}
    />
  );
};

export default PageContent;
